

section.faqs-section {
    .text-800 {
        margin: 0 30px !important;
        color: #393C41 !important;
    }
    .display-3 {
        color: #363D4B !important;
        line-height: 51px;
    }
    .text-600 {
        color: #101215 !important;
    }
    #accordion div a:not(.collapsed) > span:first-of-type,
    #accordion div a:not(.collapsed) i {
        color: #ffffff !important;
        font-size: 15px;
        letter-spacing: 0.12em;
    }
    #accordion div .h6:not(.collapsed) {
        background-color: #926215;
        padding: 25px;
    }
    #accordion div .h6 {
        padding: 25px;
        background-color: #ECEFF2;
        margin: 0;
        font-size: 15px !important;
        font-weight: 600 !important;
        letter-spacing: 0.12em !important;
    }
    #accordion {
        .border-bottom {
            border-bottom: 1px solid rgba(131,129,127,0) !important;
            padding: 5px 0 !important;
        }
    } 
    &.has-contact,
    &.has-testimonials {
        padding: 170px 0 150px;
        @media screen and (max-width: 1600px) {
            & {
                padding-top: 100px !important;
            }
        }
        @media screen and (max-width: 991px) {
            & {
                padding-top: 100px !important;
            }
        }
    }
    
    &.no-contact.has-services {
        padding-top: 150px !important;
    }
    &.has-testimonials.no-contact.no-services {
        padding-top: 270px !important;
        @media screen and (max-width: 1600px) {
            & {
                padding-top: 280px !important;
            }
        }
        @media screen and (max-width: 991px) {
            & {
                padding-top: 100px !important;
            }
        }
    }
        
}